import React from 'react';
import {
  Gutter,
  Banner,
  Flashy,
  Paragraph,
  ParagraphHeader,
  ReadonlyTextarea,
  useApi,
} from '@axiom/ui';
import { Candidate, CandidateWorkFeedItem } from '@axiom/validation';
import { CandidateOpportunitiesConst, Opportunity } from '@axiom/const';

import { WorkFeedUtil } from '../../utils/WorkFeedUtil';
import { RejectionLossCodesUtil } from '../../utils/RejectionLossCodesUtil';
import { CandidateOpportunitiesLegacyApi } from '../../api/candidate-opportunities-legacy-api';

const { CLOSED_LOST, CLOSED_WON } = Opportunity;
const { CandidateStatuses } = CandidateOpportunitiesConst;

export const WorkFeedCardMessages = ({
  workFeedItem,
  candidate,
}: {
  workFeedItem: CandidateWorkFeedItem;
  candidate: Candidate;
}) => {
  const [{ data: candidateOpportunities }] = useApi(
    CandidateOpportunitiesLegacyApi.readOpportunitiesByCandidateId(candidate.id)
  );

  const candidateOpportunity = candidateOpportunities.find(
    co => co.opportunityId === workFeedItem.id
  );

  const { isSubmitted, isSelected, isInterested } =
    WorkFeedUtil.getWorkFeedCandidateStatuses(workFeedItem);

  const candidateStatusesForClientRejectedMessages = [
    CandidateStatuses.Cooled,
    CandidateStatuses.Rejected,
  ];

  // Rejected by Client Messaging
  if (
    candidateOpportunity?.rejectionLossCode &&
    candidateOpportunity.rejectionLossCode in
      RejectionLossCodesUtil.rejectedByClientMessages &&
    candidateStatusesForClientRejectedMessages.includes(
      workFeedItem.candidateStatus
    )
  ) {
    const message =
      RejectionLossCodesUtil.rejectedByClientMessages[
        candidateOpportunity.rejectionLossCode as keyof typeof RejectionLossCodesUtil.rejectedByClientMessages
      ];
    return (
      <Gutter bottom="16px">
        <Banner name="REJECTED_MESSAGE">{message}</Banner>
      </Gutter>
    );
  }

  // Rejected Messaging w/ rejectionLossCodeCustomMessage
  if (
    workFeedItem.rejectionLossCode &&
    workFeedItem.rejectionLossCodeCustomMessage
  ) {
    return (
      <Gutter bottom="16px">
        <Banner name="REJECTED_CUSTOM_MESSAGE">
          <ReadonlyTextarea
            value={workFeedItem.rejectionLossCodeCustomMessage}
          />
        </Banner>
      </Gutter>
    );
  }

  // Rejected Messaging
  if (
    workFeedItem.rejectionLossCode in RejectionLossCodesUtil.defaultMessages
  ) {
    return (
      <Gutter bottom="16px">
        <Banner name="REJECTED_MESSAGE">
          {
            RejectionLossCodesUtil.defaultMessages[
              workFeedItem.rejectionLossCode as keyof typeof RejectionLossCodesUtil.defaultMessages
            ]
          }
        </Banner>
      </Gutter>
    );
  }

  // Closed Lost OR opp is hidden OR BE flag
  // Opportunity is Closed Won but candidate was not selected
  if (
    workFeedItem.opportunity.stage === CLOSED_LOST ||
    workFeedItem.opportunity.isExcludedFromFeed ||
    WorkFeedUtil.isUnavailableToCandidate(workFeedItem) ||
    (workFeedItem.opportunity.stage === CLOSED_WON && !isSelected)
  ) {
    return (
      <Gutter bottom="16px">
        <Banner name="UNREMEDIATED_MESSAGE">
          <Paragraph>
            <Flashy color="textSecondary">
              <ParagraphHeader as="span">
                The opportunity is no longer available
              </ParagraphHeader>
            </Flashy>{' '}
            due to a change in the opportunity status. We encourage you to
            continue reviewing the feed as new opportunities are added daily.
          </Paragraph>
        </Banner>
      </Gutter>
    );
  }

  // Interested Message
  if (isInterested) {
    return (
      <Gutter bottom="16px">
        <Banner name="INTERESTED_MESSAGE" impact="high" type="critical">
          <Paragraph>
            <Flashy color="textBrand">
              <ParagraphHeader as="span">We are on it!</ParagraphHeader>
            </Flashy>{' '}
            Axiom is carefully considering your profile for this opportunity.
            When we have an update we'll post it right here so please check
            back.
          </Paragraph>
        </Banner>
      </Gutter>
    );
  }

  // Submitted Message
  if (isSubmitted || isSelected) {
    return (
      <Gutter bottom="16px">
        <Banner name="SUBMITTED_MESSAGE" impact="high" type="critical">
          <Paragraph>
            <Flashy color="textBrand">
              <ParagraphHeader as="span">Great news!</ParagraphHeader>
            </Flashy>{' '}
            Your profile has been submitted for the client's consideration. You
            can track the status of this opportunity in Submissions on your
            Engagements page.
          </Paragraph>
        </Banner>
      </Gutter>
    );
  }

  return null;
};
